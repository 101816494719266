<template>
  <div class="p-6"><subscription-overview :show-filter="false" /></div>
</template>

<script>
import SubscriptionOverview from '@/components/subscription/subscriptionOverview.vue';
export default {
  components: {
    SubscriptionOverview
  },
  name: 'Teams-Dashboard',

  // mounted() {},
  computed: {},
  methods: {}
};
</script>

<style></style>
